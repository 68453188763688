<template>
  <div>
      <el-header class="layout-header">
        <nav class="layout-nav">
          <h1 class="logo-wrap">
            <a :href="jumpLink">
              <img
                src="~/assets/img/header/logo.png"
                alt="企业社保代缴,电子工资条,薪资代发"
                title="壹人事"
              />
            </a>
          </h1>
          <div class="wrap_5th">
            <i></i>
            <img src="~/assets/img/header/icon_5th.png" alt="5周年" />
          </div>
          <ul class="navBar">
            <li class="home">
              <a class="transi-all" :href="jumpLink">
                <span>首页</span>
              </a>
            </li>
            <li class="product">
              <a class="transi-all" :href="jumpLink + '/productsservices'" rel="nofollow">
                <span>产品与服务</span>
                <img
                  style="margin-left:2px;"
                  class="transi-all"
                  src="~/assets/img/header/down-arrow.svg"
                  alt="壹人事-产品"
                />
              </a>
              <div class="product_down">
                <div class="product_box">
                  <div class="product_list">
                    <p class="product_title">在线人事外包</p>
                    <a class="product_item nav-pro-social" :href="jumpLink+'/shebaodaijiao.html'">
                      <img src="~/assets/img/header/nav_social.png" alt="企业社保代缴" />
                        <div class="item_top">
                            <h2 class="item_title">企业社保代缴<img class="hot_icon" src="~/assets/img/header/icon_hot.png" /></h2>
                            <p class="item_text">覆盖110+城市，全程在线操作</p>
                        </div>
                    </a>
                    <a class="product_item nav-pro-deposit" :href="jumpLink+'/depositservice.html'">
                        <img src="~/assets/img/header/nav_deposit.png" alt="社保账户托管" />
                        <div class="item_top">
                            <h2 class="item_title">社保账户托管</h2>
                            <p class="item_text">企业账户托管，在线提交增减员</p>
                        </div>
                    </a>
                    <a class="product_item nav-pro-agent" :href="jumpLink+'/daifagongzi.html'">
                        <img src="~/assets/img/header/nav_pay.png" alt="薪资代发" />
                        <div class="item_top">
                            <h2 class="item_title">薪资代发</h2>
                            <p class="item_text">最快6秒到账，45+城市本地报个税</p>
                        </div>
                    </a>
                  </div>
                  <div class="product_list">
                      <p class="product_title">在线HR软件</p>
                      <a class="product_item nav-pro-roster" :href="jumpLink+'/yuangonghuamingce.html'">
                          <img src="~/assets/img/header/nav_roster.png" alt="员工花名册" />
                          <div class="item_top">
                              <h2 class="item_title">员工花名册</h2>
                              <p class="item_text">免费申请试用，自动生成分析图表</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-salary" :href="jumpLink+'/gongzitiao.html'">
                          <img src="~/assets/img/header/nav_salary.png" alt="电子工资条" />
                          <div class="item_top">
                              <h2 class="item_title">电子工资条<img class="hot_icon" src="~/assets/img/header/icon_hot.png" /></h2>
                              <p class="item_text">免费试用，多端群发，手写签名确认</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-entry" :href="jumpLink+'/zhinengruzhi.html'">
                          <img src="~/assets/img/header/nav_entry.png" alt="智能入职" />
                          <div class="item_top">
                              <h2 class="item_title">智能入职</h2>
                              <p class="item_text">一键发送Offer，小程序极速办理入职</p>
                          </div>
                      </a>
                  </div>
                  <div class="product_list">
                      <p class="product_title">员工福利平台</p>
                      <a class="product_item nav-pro-holiday" :href="jumpLink+'/welfare.html'">
                          <img src="~/assets/img/header/nav_welfare.png" alt="节日福利" />
                          <div class="item_top">
                              <h2 class="item_title">节日福利<img class="hot_icon" src="~/assets/img/header/icon_hot.png" /></h2>
                              <p class="item_text">员工N选1礼包，企业集采，员工自领</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-insure" :href="jumpLink+'/yuangongshangbao.html'">
                          <img src="~/assets/img/header/nav_insure.png" alt="员工商保" />
                          <div class="item_top">
                              <h2 class="item_title">员工商保</h2>
                              <p class="item_text">按月付费，险种多样，在线投停保</p>
                          </div>
                      </a>
                      <a class="product_item nav-pro-integral" :href="jumpLink+'/jifenfuli.html'">
                        <img src="~/assets/img/header/nav_integral.png" alt="积分福利" />
                        <div class="item_top">
                            <h2 class="item_title">积分福利</h2>
                            <p class="item_text">弹性员工福利，多场景，双节税</p>
                        </div>
                      </a>
                  </div>
                </div>
              </div>
            </li>
            <li class="info">
              <a class="transi-all" :href="jumpLink + '/newslist.html'">
                <span>政策与支持</span>
              </a>
            </li>
            <li v-if="false">
              <a  class="transi-all"
                :href="communityLink" rel="nofollow">
                <span>社区资讯</span>
              </a>
            </li>
            <li class="active">
              <a
                href="/"
              >
                <span>福利商城</span>
              </a>
            </li>
            <li class="about-person">
                <a class="transi-all" href="" rel="nofollow">
                    <span>关于壹人事</span>
                    <img class="transi-all" src="~/assets/img/header/down-arrow.svg" alt="壹人事-产品">
                </a>
                <div class="about-person-down">
                    <div class="about-person-down-content">
                        <a class="item" :href="jumpLink+'/aboutus.html'">
                            <div class="item-title-box">
                                <img src="~/assets/img/header/about-person1.png" />
                                <span>壹人事介绍</span>
                            </div>
                            <p class="msg">企业人事服务云平台，20年经验110+城市 ></p>
                        </a>
                        <a class="item" href="https://www.echatsoft.com/visitor/pc/chat.html?companyId=12203&echatTag=website">
                            <div class="item-title-box">
                                <img src="~/assets/img/header/about-person2.png" />
                                <span>在线客服</span>
                            </div>
                            <p class="msg">立体客户服务体系，立即接入人工坐席 ></p>
                        </a>
                        <a class="item" :href="jumpLink+'/solution.html'">
                            <div class="item-title-box">
                                <img src="~/assets/img/header/about-person3.png" />
                                <span>客户案例</span>
                            </div>
                            <p class="msg">2.6万+企业，成功推动客户人事管理提效 ></p>
                        </a>
                        <a class="item" :href="jumpLink+'/shebaocesuan.html'">
                            <div class="item-title-box">
                                <img src="~/assets/img/header/about-person4.png" />
                                <span>计算工具</span>
                            </div>
                            <p class="msg">五险一金/工资个税/养老金计算，一秒出结果 ></p>
                        </a>
                        <a class="item" :href="jumpLink+'/helpCenter.html'">
                            <div class="item-title-box">
                                <img src="~/assets/img/header/about-person5.png" />
                                <span>帮助中心</span>
                            </div>
                            <p class="msg">把您的人事问题告诉我们，即刻为您解答 ></p>
                        </a>
                        <a class="item" :href="jumpLink+'/AppDownload.html'">
                            <div class="item-title-box">
                                <img src="~/assets/img/header/about-person6.png" />
                                <span>企业端APP下载</span>
                            </div>
                            <p class="msg">24小时在线操作 ，您的移动企业人事管理平台 ></p>
                        </a>
                        <a class="item" :href="jumpLink+'/noviceschool.html'">
                            <div class="item-title-box">
                                <img src="~/assets/img/header/about-person7.png" />
                                <span>新手学堂</span>
                            </div>
                            <p class="msg">1分钟视频，真实系统演示，HR快速上手 ></p>
                        </a>
                        <!-- <a class="item" href="">
                            <div class="item-title-box">
                                <img src="~/imgs/section/header/about-person8.png" />
                                <span>员工端APP下载</span>
                            </div>
                            <p class="msg">专为企业员工打造的服务app，福利自主兑换 ></p>
                        </a> -->
                    </div>
                </div>
            </li>
          </ul>
          <div class="search">
            <el-input v-if="$route.name !=='globalSearch'"
                id="searchContent"
                :placeholder="defaultHotWord"
                v-model="searchContent" 
                @keyup.enter.native="search"
                maxlength="30">
            </el-input>
            <i @click="search" id="sousuo"></i>
          </div>
          <!-- 模糊查询 -->
          <div class="fuzzy_query" style="display:none;">
            <ul class="result_content"></ul>
          </div>
          <!-- 热门词组 -->
          <div class="hot_search" style="display:none;">
            <ul class="hot_result">
            </ul>
          </div>
          <div class="login-wrap">
            <!-- <a class="nrb-item-box" :href="jumpLink+'/AppDownload.html'" target="_blank"
              @mouseenter="handleEnter('down')" @mouseleave="handleLeave('down')"
            >
              <img class="nrb-item-icon" src="~/assets/img/header/down.png" alt="下载壹人事App" />
              <div class="nrb-down-box nrb-item-box-dialog-down">
                  <img src="~/assets/img/header/qr-code.png" />
                  <p>扫码下载壹人事App</p>
              </div>
            </a>
            <div class="nrb-item-box" style="margin-right:0;"
              @mouseenter="handleEnter('service')" @mouseleave="handleLeave('service')"
            >
                <img class="nrb-item-icon" src="~/assets/img/header/service.png" alt="壹人事客服" />
                <div class="nrb-service-box nrb-item-box-dialog-service">
                    <div class="text1">客服电话：400-189-1900</div>
                    <div class="text1">工作时间：工作日 9:00-18:00</div>
                    <div class="text2">非工作时间欢迎在线留言</div>
                    <div class="service-btn" onclick="javascript:window.open('https://www.echatsoft.com/visitor/pc/chat.html?companyId=12203&echatTag=website','','width=1000,height=700,top=200,left=200')"></div>
                </div>
            </div> -->
            <!-- 登录 -->
            <div style="cursor:pointer;margin:0 22px;" v-if="!userData.privateUserID" @click="openLoginDialogCom">企业工作台</div>
            <div class="login-after login" v-if="userData.privateUserID">
              <a
                id="workbench"
                class="go-register transi-all radius-20"
                style="cursor: pointer;color:#0089cd;margin:0 22px;"
                @click="pushWorkbench(userData)"
                rel="nofollow"
                >企业工作台</a
              >
              <a class="user-img transi-all"  @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                <img
                  id="chouti-img"
                  class="img"
                  :src="userData.privateUserInfo.privatePhotoUrl?userData.privateUserInfo.privatePhotoUrl:defaultImg"
                />
              </a>
              <a id="chouti_name" class="name"  @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">{{userData.privateUserInfo.nickName}}</a>
            </div>
            <div v-else class="nrb-no-login-box">
                <img class="nrb-item-icon" src="~/assets/img/header/user.png" alt="登录/注册" @click="openLoginDialogCom" />
                <span class="nrb-no-login-text header-login-in" @click="openLoginDialogCom">登录 | 注册</span>
                <i class="nrb-no-login-send20"></i>
            </div>
            <div class="signing_box" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                <a :href="jumpLink+'/UserCenter/Index'">用户中心</a>
                <a @click="pushWorkbench(userData)">进入工作台</a>
                <a :href="jumpLink+'/UserCenter/UserTask'">壹豆获取<i class="red_spot"></i></a>
                <a :href="jumpLink+'/UserCenter/UserAccountSetting'">账号设置</a>
                <a @click="loginOut">退出登录</a>
              </div>
          </div>
        </nav>
      </el-header>
      <Drawer />
      <WebSideWorker />
  </div>
</template>
<script>
import $ from "jquery";
import Drawer from "./userDrawer.vue";
import WebSideWorker from "./websideworker";
import { getReqUserInfo, getFuzzyQuery, getHotSearch } from "network/welfareMall_api.js";
import { mapState } from "vuex";

export default {
  middleware: "layout",
  components: {
    Drawer,
    WebSideWorker,
  },
  data() {
    return {
      userData: {},
      categoryList: {},
      jumpLink: this.preUrlHome,
      jumpLoginLink: this.preUrlOld,
      searchContent: "",
      communityLink: this.communityUrl,
      defaultImg: require("../assets/img/header/tx.png"), //用户默认头像
      hotsearchIcon: require("../assets/img/header/hot_search.png"), //热门词组icon
      defaultHotWord: "",
    };
  },
  watch: {
    $route(to, from) {
      this.searchContent = "";
    },
    searchContent: {
      //监听类别id
      handler() {
        if(this.searchContent.length >0){
          $('.hot_search').hide();
          this.getFuzzyQueryResult(this.searchContent);
        } else {
          $(".fuzzy_query").hide();
          $(".hot_search").show();
        }
      },
      deep: true,
    },
    monitor() {
      if (this.$store.state.useInfo) {
        this.userData = this.$store.state.useInfo;
      }
    },
  },
  computed: {
    // 映射state
    ...mapState(["useInfo", "companyInfo", "cartNum"]),
    monitor() {
      return this.$store.state.useInfo;
    },
  },
  created() {
    this.getHotWord();
  },
  mounted() {
    let _this = this;
    /* 导航栏hover显示下拉菜单 */
    $(".layout-nav .navBar > li.product").mouseenter(function () {
      $(".product_down").show().slideDown("fast");
    });
    $(".layout-nav .navBar > li.product").mouseleave(function () {
      $(".product_down").hide().slideUp("fast");
    });
    $(".product_down").mouseleave(function () {
      $(this).slideUp("fast");
    });
    // 关于壹人事
    $('.about-person').mouseenter(function () {
        $('.about-person-down').show().slideDown('fast');
    })
    $('.about-person').mouseleave(function () {
        $('.about-person-down').hide().slideUp('fast');
    })

    //全局搜索 模糊查询 热门词组
    $("#searchContent").focus(function () {
      // console.log(_this.searchContent)
      _this.getFuzzyQueryResult(_this.searchContent);
    });

    // 监听点击在输入框以外区域是否关闭搜索结果
    document.addEventListener("click", function (event) {
      // 检查事件是否发生在id为'searchContent'的元素之外
      if (event.target.id !== "searchContent" && event.target.id !== "searchContent") {
        $(".fuzzy_query").hide();
      } else {
        if($('.result_content'.html())){
          $(".fuzzy_query").show();
        }
      }
    });

    $("#searchContent").blur(function () {
      setTimeout(100);
      $(".hot_search").hide();
    });
    $(".hot_search").mouseenter(function () {
      $(".hot_search").show();
    });
    $(".hot_search").mouseleave(function () {
      setTimeout(100);
      $(".hot_search").show();
    });
  },
  methods: {
    //全局搜索
    search() {
      if (this.searchContent.length) {
        window.location.href = this.jumpLink + "/globalsearch.html?search=" + this.searchContent;
      } else {
        window.location.href = this.jumpLink + "/globalsearch.html?search=" + this.defaultHotWord;
      }
    },
    //全局搜索 引导词
    async getHotWord() {
      let res = await getHotSearch(1);
      if (res.Data) {
        this.defaultHotWord = res.Data[0].Name;
      }
    },
    //全局搜索 模糊查询
    async getFuzzyQueryResult(search) {
      if (search == "") {
        let res = await getHotSearch(2);
        if (res.Data) {
          this.getHotHtml(res.Data);
        } else {
          console.log(res);
        }
      }else{
        let res = await getFuzzyQuery(search)
        if(res.length != 0){
          this.getHtml(res);
        }else{
          //console.log(res)
          $('.fuzzy_query').hide();
        }
      }
    },
    getHtml(res) {
      let _this = this;
      let resultListStr = '';
      let resNew = res.slice(0, 5);
      if (resNew !== '' && resNew !== null) {
          for (let i = 0; i < resNew.length; i++) {
              resultListStr += `<li>
                            ${resNew[i].Title}
                        </li>`;
          }
      }
      if (resultListStr.length) {
          $('.result_content').html(resultListStr);
          $('.fuzzy_query').show();
          $('.hot_search').hide();
          $('.result_content').click((e) => {
              let liText = '';
              if (e.target.tagName != 'UL') {
                  if (e.target.tagName != 'LI') {
                      liText = e.target.parentNode.innerText;
                  } else {
                      liText = e.target.innerText;
                  }
              }
              window.location.href = _this.jumpLink + '/globalsearch.html?search=' + liText
          })
      } else {
          $('.fuzzy_query').hide();
      }
    },
    showSearch() {
      if (this.searchContent.length > 0) {
        $(".fuzzy_query").show();
        $(".hot_search").hide();
      }
    },
    getHotHtml(res) {
      let resultListStr = "";
      if (res !== "" && res !== null) {
        //console.log(res)
        resultListStr += `<div class="hot_header">热门搜索<img src="${this.hotsearchIcon}"></div>`;
        let resNew = res.slice(0, 5);
        for (let i = 0; i < resNew.length; i++) {
          resultListStr += `<li><a href="${this.jumpLink}/globalsearch.html?search=${resNew[i].Name}">${resNew[i].Name}</a></li>`;
        }
        if (resultListStr.length) {
          $(".hot_result").html(resultListStr);
          $(".hot_search").show();
        } else {
          $(".hot_search").hide();
        }
      }
    },
    //点击工作台
    async pushWorkbench() {
      let pctoken = this.GetTokenFromCookie("pcToken");
      console.log(pctoken);
      if (!pctoken) {
        return;
      }
      let res = await getReqUserInfo();
      if (res.code == 200) {
        let userDataList = res.data;
        if (userDataList.companyList.length == 0) {
          this.$store.commit("openAddCompany");
        } else {
          var userID = userDataList.companyList[0].userID;
          location.href = this.jumpLoginLink + "/Account/Login?token=" + this.GetTokenFromCookie("pcToken") + "&userId=" + userID;
        }
      } else {
        this.$message.warning(res.msg);
        this.loginOut();
      }
    },
    // 删除cookie
    delCookie() {
      let cookies = document.cookie.split(";");
      cookies.forEach(function (cookie) {
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      });
    },
    //点击退出
    loginOut() {
      let _this = this;
      $.ajax({
        url: _this.jumpLoginLink + "/Account/LogoutOffice?client=officesite",
        type: "get",
        timeout: 5000,
        dataType: "JSONP", // 处理Ajax跨域问题
        data: { callback: "?" },
        // 默认情况下，标准的跨域请求是不会发送cookie的
        xhrFields: {
          withCredentials: true,
        },
        success: function () {
          $.cookie("pcToken", "", { expires: -1 });
          $.cookie("taskJumpId", "", { expires: -1 });
          _this.delCookie();
          localStorage.removeItem("tokenExpiration");
          localStorage.removeItem("tokenClient");
          console.log($.cookie("pcToken"), 11122);
          window.location.href = _this.preUrlHome;
        },
      });
    },
    // 用户中心
    handleMouseEnter() {
      $(".signing_box").show();
    },
    handleMouseLeave() {
      $(".signing_box").hide();
    },
    // app和客服
    handleEnter(name) {
      $(".nrb-item-box-dialog-" + name).show();
    },
    handleLeave(name) {
      $(".nrb-item-box-dialog-" + name).hide();
    },
    // 登录
    openLoginDialogCom() {
      this.$bus.$emit("openLoginDialogCom");
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.el-col {
  float: left;
}
.nav-right {
  width: 150px;
  float: right;
}
.nav-right li {
  display: inline-block;
  width: 50px;
  line-height: 60px;
  text-align: center;
}
.el-header {
  width: 1200px;
  min-height: 70px;
  line-height: 70px;
}

.el-main {
  width: 1200px;
  padding: 0;
  margin-top: 70px;
  overflow: auto;
  overflow-x: hidden;
}

/* 公共头部 */
.layout-header {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
  .transi-all {
    transition: all 0.2s ease-in;
  }
}
.layout-nav {
  display: flex;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.layout-nav > div,.layout-nav > ul {
  height: 100%;
  display: flex;
  align-items: center;
}
/* logo 开始 */
.layout-nav .logo-wrap {
  width: 120px;
  font-weight: normal;
}
.layout-nav .logo-wrap .logo {
  margin-right: 10px;
}
.layout-nav .logo-wrap img {
  max-width: 100%;
  margin-top: 16px;
}
/* logo 结束 */
.layout-nav .wrap_5th {
    margin-left: 10px;
}

.layout-nav .wrap_5th i {
    display: inline-block;
    width: 1px;
    height: 30px;
    background: #eee;
}

.layout-nav .wrap_5th img {
    width: 58px;
    height: 54px;
    background-size: cover;
    margin-left: 15px;
}
/* 右边登录注册、下载 */
.layout-nav .login-wrap {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  width: 280px;
  color: #3c3c3c;
  font-size: 16px;
}
.layout-nav .login-wrap a {
  display: inline-block;
}
.layout-nav .login-wrap .login-after {
  display: flex;
  height: 70px;
  line-height: 70px;
}
/* 账号拆分 头像 */
.layout-nav .login-wrap .login-after .user-img {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-top: 8px;
  cursor: pointer;
  .img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.layout-nav .login-wrap a.name {
  width: 80px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-left: 5px;
  cursor: pointer;
}
.layout-nav .login-wrap .signing_box {
  position: fixed;
  top: 62px;
  display: none;
  width: 139px;
  height: 205px;
  padding-top: 12px;
  margin-top: -16px;
  margin-left: 195px;
  background: url(../assets/img/header/signing_box.png);
  a {
    display: block;
    width: 130px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    margin: 0 auto;
    color: #3c3c3c;
    &:hover {
      cursor: pointer;
      background: #f5f5f5;
    }
    .red_spot {
      position: relative;
      top: -10px;
      right: -6px;
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #f25130;
    }
  }
}
/* 账号拆分 头像 结束*/

/* 搜索 */
/deep/.layout-nav .search{
  position: relative;
  width: 190px;
  #sousuo{
    position: absolute;
    right: 12px;
    top: 27px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    background: url('../assets/img/header/sousuo.png') no-repeat;
  }
  #sousuo:hover{
    background: url('../assets/img/header/sousuo_hover.png') no-repeat;
  }
  .el-input__inner{
    width: 190px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 36px;
  }
  ::placeholder{
    color: RGB(176,176,176);
  }
}
/* 全局搜索 模糊查询 */
.fuzzy_query {
  position: absolute;
  top: 60px;
  height: auto !important;
  background: #fff;
  width: 334px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  @media screen and (max-width: 1980px) {
    left: 56% !important;
  }
  @media screen and (max-width: 1440px) {
    left: 59% !important;
  }
  @media screen and (max-width: 1200px) {
    left: 710px !important;
  }
  /deep/.result_content {
    padding: 25px;
    li {
      margin-bottom: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      color: #3c3c3c;
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    li:hover {
      color: #3c3c3c;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}
/* 热门搜索 */
.hot_search {
  position: absolute;
  top: 60px;
  height: auto !important;
  background: #fff;
  width: 334px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  @media screen and (max-width: 1980px) {
    left: 56% !important;
  }
  @media screen and (max-width: 1440px) {
    left: 59% !important;
  }
  @media screen and (max-width: 1200px) {
    left: 710px !important;
  }
  /deep/.hot_result {
    width: 314px;
    padding: 10px;
    .hot_header {
      line-height: 20px;
      margin-top: 5px;
      padding-left: 10px;
      padding-bottom: 15px;
      font-size: 16px;
      font-weight: bold;
      color: #3c3c3c;
      border-bottom: 1px solid #eaeaea;
      img {
        margin-left: 7px;
        vertical-align: middle;
      }
    }
    li {
      margin: 10px 10px 20px;
      a {
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #0089cd;
        display: -webkit-box;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    li:nth-child(2) {
      margin-top: 15px;
    }
    li:last-child {
      margin-bottom: 10px;
    }
  }
}
/* 中间页面跳转 导航栏 */
.layout-nav .navBar {
    flex: 1;
    justify-content: space-between;
    padding: 0 26px 0 64px;
}

.layout-nav .navBar > li {
  position: relative;
  height: 100%;
  cursor: pointer;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.layout-nav .navBar > li > a {
  font-size: 16px;
  color: #3c3c3c;
}
.layout-nav .navBar > li > a > img {
  margin-left: 5px;
}

.layout-nav .navBar > li:hover > a > img {
  transform: rotateX(-180deg);
}
/* 导航栏active */
.layout-nav .navBar li.active a span {
  border-bottom: 2px solid #0089cd;
  padding-bottom: 4px;
  color: #0089cd;
}
/* 导航栏hover */
.layout-nav .navBar > li:hover > a {
  color: #0089cd;
}

.layout-nav .navBar > li:hover > a > img {
  transform: rotateX(-180deg);
}

/* 导航下拉菜单hover && active */
.layout-nav .navBar .product_down a.active {
  background: #f5f6f7;
  border-radius: 4px;
}
/* 产品与服务下拉框 */
.product_down {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  height: auto;
  background: #fff;
  margin-top: -2px;
  cursor: default;
  box-shadow: 0px 3px 6px 1px rgba(152, 152, 152, 0.1);
}
.product_down .product_box {
  width: 1200px;
  margin: 0 auto;
  padding-top: 35px;
  padding-bottom: 35px;
}
.product_down .product_box .product_list {
  display: inline-block;
  width: 280px;
  float: left;
  margin-left: 70px;
}
.product_down .product_box .product_list:nth-child(1) {
  margin-left: 0;
}
.product_down .product_box .product_list .product_title {
  font-size: 18px;
  font-weight: 500;
  color: #3c3c3c;
  line-height: 24px;
  width: 270px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
  margin-left: 10px;
}
.product_down .product_box .product_list .product_item {
  display: block;
  margin-bottom: 20px;
}
.product_down .product_box .product_list .product_item:hover {
  background: #f5f6f7;
  border-radius: 4px;
}
.product_down .product_box .product_list .product_item img {
  display: inline-block;
  float: left;
  width: 30px;
  height: 30px;
  background-size: 100%;
  margin: 12px 10px 0 10px;
}
.product_down .product_box .product_list .product_item .item_top {
  padding-top: 12px;
  padding-bottom: 12px;
}
.product_down .product_box .product_list .product_item .item_top .item_title {
  font-size: 16px;
  color: #3c3c3c;
  line-height: 16px;
  padding-bottom: 4px;
}
.product_down .product_box .product_list .product_item .item_top .item_title .hot_icon {
  display: inline-block;
  width: 30px;
  height: 15px;
  margin: 0;
  position: relative;
  float: none;
  margin-left: 10px;
}
.product_down .product_box .product_list .product_item .item_top .item_text {
  font-size: 12px;
  color: #9a9a9a;
  line-height: 16px;
}

/* 输入框 placeholder 样式优化 */
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder {
  color: #a6a6a6;
  font-size: 14px;
}
input::-moz-placeholder,textarea::-moz-placeholder {
  color: #a6a6a6;
  font-size: 14px;
}
input:-ms-input-placeholder,textarea:-ms-input-placeholder {
  color: #a6a6a6;
  font-size: 14px;
}
input::placeholder,textarea::placeholder {
  color: #a6a6a6;
  font-size: 14px;
}
.user-drawer {
  height: 100%;
}
/* 新增企业 */
.add-company {
  height: 500px;
  z-index: 10001;
  .addcom-wrap {
    text-align: center;
    .pic {
      line-height: 28px;
      padding-left: 117px;
      text-align: left;
      span {
        font-size: 14px;
        color: #3c3c3c;
      }
    }
    .addcom-item {
      align-items: center;
      position: relative;
      .logo {
        width: 94px;
        height: 94px;
        border-radius: 10px;
      }
      .input-block {
        display: flex;
        margin-left: 0;
      }
      .default-input {
        width: 224px;
        height: 32px;
      }
      input::-webkit-input-placeholder {
        /* WebKit browsers */
        font-size: 14px;
        color: #bfbfbf;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        font-size: 14px;
        color: #bfbfbf;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        font-size: 14px;
        color: #bfbfbf;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        font-size: 14px;
        color: #bfbfbf;
      }
    }
    .addcom-item:nth-child(1) {
      height: 154px;
      margin-bottom: 0;
      .uploadImg2 {
        position: absolute;
        width: 94px;
        height: 94px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0;
        cursor: pointer;
        z-index: 30;
        .logo {
          margin: 30px 0;
          width: 94px;
          height: 94px;
          border-radius: 10px;
        }
      }
      .resetLogo-backg {
        position: absolute;
        width: 94px;
        height: 94px;
        background: #71bfff;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        z-index: 11;
        border-radius: 10px;
        h4 {
          color: #fff;
          background: #385f7f;
          height: 36px;
          width: 100%;
          text-align: center;
          line-height: 36px;
          margin-top: 58px;
          border-radius: 0 0 10px 10px;
        }
      }
    }
    .addcom-item:nth-child(5) {
      margin-bottom: 0;
    }
    .email-pic {
      width: 210px;
      margin: 0 auto;
      padding: 5px;
      font-size: 12px;
      line-height: 14px;
      text-align: left;
      color: #13c2c2;
    }
    .addcom-btn-box {
      margin-left: 0;
    }
  }
}
/* 选择公司 */
.company {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  height: 442px;
  background: #fff;
  z-index: 10001;
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  .box-head {
    background: #0089cd;
    color: #fff;
    height: 50px;
    line-height: 50px;
    display: flex;
    border-radius: 10px 10px 0 0;
    .head-title {
      font-size: 14px;
      padding: 0 0 0 30px;
      position: relative;
      margin: 0;
    }
    .close-icon {
      margin-top: 15px;
    }
  }
  .box-main {
    margin: 16px 0;
    line-height: 2;
    padding: 0;
    /* 公司信息 */
    .company-box {
      margin-top: 27px;
      max-height: 355px;
      overflow: hidden;
      overflow-y: auto;
      .company-item {
        padding: 12px 0 11px 30px;
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        cursor: pointer;
        .com-logo {
          height: 32px !important;
          width: 32px !important;
          border-radius: 4px;
        }
        .img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden;
        }
        .com-name {
          margin-left: 11px;
          color: #333333;
          font-size: 16px;
          max-width: 210px;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
        .com-name:hover {
          color: #1890ff;
        }
      }
      .company-item:hover {
        background: #e6f7ff;
      }
    }
  }
}
/* 遮罩层 */
.mask {
  background-color: rgb(0, 0, 0);
  opacity: 0.3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  position: fixed;
}
/* 弹框基础样式设置 */
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 450px;
  border-radius: 12px;
  background: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.3);
  .close-icon {
    top: 0;
  }
}
.dialog-head {
  height: 50px;
  line-height: 50px;
  background: #0089cd;
  padding: 0;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  .head-title {
    font-size: 14px;
    color: #fff;
  }
}
/* 弹框关闭按钮 */
.close-icon {
  position: absolute;
  top: -15px;
  right: 15px;
  img {
    width: 16px;
    height: 16px;
    border: 1px solid #2e8ded;
    background: #2e8ded;
  }
}
.close-icon img:hover {
  cursor: pointer;
  border: 1px solid #208ce3;
  background: #208ce3;
}
/* 弹框输入框基础样式 */
.addcom-item {
  display: flex;
  margin-bottom: 16px;
  justify-content: center;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  color: #3c3c3c;
  .form-label {
    width: 80px;
    text-align: right;
  }
  .input-inline {
    display: flex;
    margin-left: 10px;
    width: 224px;
    i {
      position: absolute;
      color: #bfbfbf;
      margin-left: 198px;
    }
  }
  /* 获取验证码样式 */
  .color-blue {
    font-size: 14px;
    font-style: normal;
    margin-left: 145px !important;
    cursor: pointer;
  }
  .color-blue:hover {
    color: #0089cd !important;
  }
  .color-gray {
    color: #e0e0e0 !important;
    cursor: auto;
    text-align: center;
    font-style: inherit;
  }
}
/* 弹框确认、取消按钮设置 */
.addcom-btn-box {
  margin-left: 70px;
  .default-btn {
    min-width: 65px;
    height: 32px;
    line-height: 32px;
    margin: 30px 6px;
  }
}
/* 输入框 */
.default-input {
  display: block;
  width: 224px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  border-color: #bfbfbf;
  border-radius: 2px;
  padding-left: 10px;
  outline: 0;
}
.default-input:focus {
  border-color: #71bfff;
}
/* 按钮 */
.default-btn {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  padding: 0 18px;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.default-btn:hover {
  opacity: 0.8;
}
.btn-primary {
  border: 1px solid #c9c9c9;
  background-color: #fff;
  color: #555;
}
.btn-primary:hover {
  color: #40a9ff;
  border-color: #40a9ff;
}
.btn-normal {
  background-color: #0089cd;
}
.btn-normal:hover {
  background-color: #1e9fff;
}
/* 字体图标 */
@font-face {
  font-family: "iconfont"; /* Project id 2739610 */
  src: url("//at.alicdn.com/t/font_2739610_yovty13gddl.woff2?t=1628824588503") format("woff2"),
    url("//at.alicdn.com/t/font_2739610_yovty13gddl.woff?t=1628824588503") format("woff"),
    url("//at.alicdn.com/t/font_2739610_yovty13gddl.ttf?t=1628824588503") format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
}
.iconfont:hover {
  color: #40a9ff !important;
}
.icon-suo1:before {
  content: "\e60a";
}
.icon-xiugaiziliao:before {
  content: "\e7e0";
}
.icon-biyan:before {
  content: "\e611";
}
.icon-yanjing:before {
  content: "\e6f3";
}
/* 头部最右侧 */
.nav-right-box {
  width: 300px;
  width: 330px;
  color: #3c3c3c;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}
.nrb-item-box {
  position: relative;
  margin-right: 20px;
}
.nrb-item-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  vertical-align: middle;
}
.nrb-no-login-text {
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #3c3c3c;
  cursor: pointer;
}
.nrb-no-login-box {
  margin-left: 30px;
  position: relative;
}
.nrb-no-login-send20 {
    position: absolute;
    right: -47px;
    top: 8px;
    display: inline-block;
    width: 80px;
    height: 18px;
    background: url("../assets/img/header/send120.png") no-repeat;
    background-size: 100%;
}
.nrb-down-box {
  display: none;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 130px;
  height: 146px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.12);
  border-radius: 6px 6px 6px 6px;
  line-height: normal;
}
.nrb-down-box::before,.nrb-service-box::before {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  content: "";
  width: 16px;
  height: 8px;
  background: url("../assets/img/header/triangle-up.png") no-repeat;
  background-size: 100%;
}
.nrb-down-box > img {
  margin: 15px 15px 5px;
  width: 100px;
  height: 100px;
}
.nrb-down-box > p {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.nrb-service-box {
  display: none;
  padding: 20px 16px 0;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 228px;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.12);
  border-radius: 6px 6px 6px 6px;
  line-height: normal;
}
.nrb-service-box .text1 {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.nrb-service-box .text2 {
  margin-bottom: 28px;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
.nrb-service-box .service-btn {
  margin: 0 22px;
  display: inline-block;
  width: 152px;
  height: 30px;
  background: url("../assets/img/header/service-btn.png") no-repeat;
  background-size: 100%;
  cursor: pointer;
}
/* 关于壹人事 */
.about-person-down {
  display: none;
  position: absolute;
  top: 70px;
  left: -215px;
  width: 635px;
  height: 342px;
  background: #FFFFFF;
  box-shadow: 0px 3px 12px 1px rgba(152,152,152,0.15);
  border-radius: 0px 0px 20px 20px;
  border: 1px solid #E9E9E9;
  line-height:normal;
}
.about-person-down-content {
  padding: 16px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.about-person-down-content .item {
  margin-bottom: 8px;
  padding: 8px 10px;
  width: 280px;
  height: 70px;
  border-radius: 4px 4px 4px 4px;
  cursor: pointer;
}
.about-person-down-content .item:hover {
  background: #F5F6F7;
}
.about-person-down-content .item-title-box img {
  width: 30px;
  height: 30px;
  vertical-align: -6px;
}
.about-person-down-content .item-title-box span {
  margin-left: 5px;
  font-size: 16px;
  color: #3C3C3C;
}
.about-person-down-content .msg {
  margin-top: 8px;
  font-size: 12px;
  color: #9A9A9A;
}
</style>
